import React from 'react';
import { Container, Inner, Text, Loader } from './styles/banner';

export const Banner = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <Inner>{children}</Inner>
    </Container>
  );
};

Banner.Logo = () => {
  return (
    <svg
      width="136"
      height="60"
      viewBox="0 0 136 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3481 34.376H14.6226V40.6102H15.3481V34.376Z"
        fill="#7FBC42"
      />
      <path
        d="M18.92 34.376H19.5898L23.4966 39.3432V34.376H24.2221V40.5711H23.664L19.5898 35.4923V40.5711H18.92V34.376Z"
        fill="#7FBC42"
      />
      <path
        d="M27.3475 39.6781L27.794 39.1758C28.0699 39.4588 28.3998 39.6836 28.764 39.837C29.1283 39.9904 29.5196 40.0692 29.9149 40.0688C30.752 40.0688 31.3101 39.6223 31.3101 39.0084C31.3101 38.4503 30.9753 38.1154 29.6916 37.8363C28.4079 37.5573 27.5708 37.055 27.5708 36.0504C27.5708 35.0458 28.4638 34.3202 29.6358 34.3202C30.4256 34.2882 31.1998 34.5463 31.8124 35.0458L31.4218 35.6039C30.9401 35.1441 30.3016 34.8847 29.6358 34.8783C28.7986 34.8783 28.2963 35.3248 28.2963 35.8829C28.2963 36.4411 28.6312 36.7759 29.9707 37.055C31.3101 37.334 32.0357 37.8921 32.0357 38.8409C32.0357 39.9572 31.1427 40.6269 29.859 40.6269C28.9303 40.6493 28.0295 40.3089 27.3475 39.6781Z"
        fill="#7FBC42"
      />
      <path
        d="M36.8912 34.99H34.7704V34.376H39.6818V34.99H37.561V40.5711H36.8912V34.99Z"
        fill="#7FBC42"
      />
      <path d="M43.5886 34.376H42.863V40.6102H43.5886V34.376Z" fill="#7FBC42" />
      <path
        d="M48.779 34.99H46.714V34.376H51.6254V34.99H49.5046V40.5711H48.779V34.99Z"
        fill="#7FBC42"
      />
      <path
        d="M54.5834 38.0038V34.376H55.3089V37.948C55.3089 39.2874 56.0345 40.013 57.2065 40.013C58.3785 40.013 59.1041 39.3432 59.1041 38.0038V34.376H59.7738V37.948C59.7738 39.7339 58.7692 40.6827 57.2065 40.6827C55.6438 40.6827 54.5834 39.7339 54.5834 38.0038Z"
        fill="#7FBC42"
      />
      <path
        d="M64.9085 34.99H62.7877V34.376H67.699V34.99H65.5782V40.5711H64.9085V34.99Z"
        fill="#7FBC42"
      />
      <path
        d="M70.7687 34.376H75.2894V34.99H71.4942V37.1108H74.8987V37.7805H71.4942V39.9572H75.3452V40.5711H70.7687V34.376Z"
        fill="#7FBC42"
      />
      <path
        d="M7.86938 23.0464C8.97202 23.1147 10.0764 22.9434 11.1064 22.5441C11.4364 22.3699 11.7077 22.1022 11.8864 21.7746C12.0651 21.447 12.1432 21.074 12.111 20.7023C12.1148 20.3134 12.0195 19.93 11.8339 19.5882C11.6484 19.2464 11.3788 18.9576 11.0506 18.7489C9.94083 18.0184 8.73993 17.4367 7.47871 17.0187C6.51758 16.7063 5.58417 16.3142 4.68814 15.8467C3.89366 15.4754 3.15983 14.9862 2.51151 14.3956C1.8888 13.8382 1.39382 13.1528 1.06041 12.3864C0.655755 11.4939 0.464664 10.5193 0.502301 9.54004C0.467245 8.53364 0.671575 7.53329 1.09849 6.62124C1.5254 5.7092 2.16271 4.91152 2.958 4.29379C4.57652 3.01013 6.80897 2.3962 9.59953 2.3962C10.9491 2.3823 12.2963 2.51328 13.6179 2.78688C14.6039 2.9474 15.5747 3.19009 16.5201 3.51243L15.5155 8.03314C14.7586 7.71857 13.9738 7.47566 13.1715 7.30759C12.0976 7.02344 10.9891 6.89193 9.87859 6.91691C8.91156 6.88344 7.95172 7.0946 7.08803 7.53084C6.76126 7.68368 6.48656 7.92912 6.29805 8.2367C6.10954 8.54427 6.0155 8.90044 6.02761 9.26099C6.0035 9.62823 6.08073 9.99505 6.25086 10.3214C6.4058 10.6802 6.65689 10.9893 6.9764 11.2144L8.31587 11.9957L10.3251 12.7771C11.4659 13.202 12.5836 13.6864 13.6738 14.2282C14.5146 14.6478 15.2863 15.1936 15.962 15.8467C16.5596 16.4243 17.0177 17.1306 17.3015 17.9117C17.5875 18.7942 17.7196 19.7192 17.6922 20.6465C17.6922 22.9906 16.855 24.7207 15.1248 25.8927C13.3947 27.0648 10.9948 27.6787 7.81357 27.6787C6.10269 27.7355 4.39247 27.5476 2.73475 27.1206C1.80554 26.9027 0.891926 26.6231 0 26.2834L0.948791 21.6511C1.92882 22.0407 2.93613 22.3578 3.9626 22.5999C5.23725 22.9324 6.55258 23.0827 7.86938 23.0464Z"
        fill="#F05B26"
      />
      <path
        d="M31.4217 2.95432H25.8406V27.048H31.4217V2.95432Z"
        fill="#F05B26"
      />
      <path
        d="M48.2767 27.0648C46.714 23.8835 45.1513 20.2558 43.5327 16.1816C41.9142 12.1074 40.4631 7.69827 39.1237 2.95432H45.0396C45.3745 4.3682 45.7652 5.81929 46.2117 7.30759C46.6582 8.87031 47.1605 10.433 47.6628 11.9957C48.1651 13.5585 48.7232 15.0654 49.2255 16.5164L50.7324 20.4232C51.2347 19.2512 51.6812 17.9675 52.2393 16.5164L53.802 11.9957C54.3043 10.433 54.7508 8.87031 55.2531 7.30759C55.7554 5.74488 56.0903 4.3496 56.4251 2.95432H62.1737C60.948 7.44103 59.4761 11.8568 57.7646 16.1816C56.1461 20.2558 54.5834 23.8835 53.0207 27.0648H48.2767Z"
        fill="#F05B26"
      />
      <path
        d="M74.8428 2.95432H69.2617V27.048H74.8428V2.95432Z"
        fill="#F05B26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.36707 49.6683C7.36707 49.6683 59.6622 44.4221 95.214 59.9934C95.976 59.6974 96.6763 59.2621 97.279 58.7097C100.404 55.8634 96.2186 53.9658 96.2186 53.9658C96.2186 53.9658 61.3923 40.5711 7.36707 49.6683Z"
        fill="#F05B26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M107.102 10.2656C107.102 10.2656 120.162 5.57744 132.887 12.498C133.494 12.261 134.045 11.8998 134.505 11.4376C136.961 9.14936 133.556 7.69827 133.556 7.69827C133.556 7.69827 120.162 2.28458 107.102 10.2656Z"
        fill="#7FBC42"
      />
      <path
        d="M117.818 33.204C117.818 33.204 108.106 30.0228 105.874 16.1258L101.632 19.6419C101.632 19.6419 103.028 30.8041 117.818 33.204Z"
        fill="#F05B26"
      />
      <path
        d="M106.32 9.54006C106.454 10.1321 106.346 10.7529 106.022 11.2658C105.697 11.7786 105.182 12.1416 104.59 12.2748C103.998 12.408 103.377 12.3006 102.865 11.9761C102.352 11.6517 101.989 11.1367 101.855 10.5447C101.753 9.94757 101.88 9.33382 102.211 8.82658C102.543 8.31935 103.054 7.95616 103.641 7.80991C103.931 7.74449 104.231 7.73752 104.523 7.78942C104.816 7.84132 105.095 7.95105 105.344 8.11217C105.594 8.27329 105.809 8.48257 105.976 8.72776C106.144 8.97294 106.261 9.2491 106.32 9.54006Z"
        fill="#F05B26"
      />
      <path
        d="M119.771 29.1856C119.771 29.1856 126.859 21.7627 122.338 8.53543L127.696 9.59584C127.696 9.59584 131.77 20.0884 119.771 29.1856Z"
        fill="#7FBC42"
      />
      <path
        d="M118.822 2.8985C118.899 3.18644 119.033 3.45602 119.216 3.69133C119.399 3.92664 119.628 4.12291 119.888 4.26856C120.148 4.41421 120.434 4.5063 120.731 4.53939C121.027 4.57248 121.327 4.5459 121.613 4.46121C122.196 4.30412 122.694 3.92249 122.997 3.39966C123.301 2.87683 123.385 2.25526 123.231 1.67065C123.155 1.3764 123.019 1.10087 122.832 0.860973C122.645 0.621073 122.412 0.421854 122.145 0.275532C121.879 0.129211 121.585 0.0388708 121.282 0.0100517C120.98 -0.0187674 120.674 0.014542 120.385 0.107937C119.812 0.276849 119.327 0.663378 119.035 1.18487C118.743 1.70636 118.667 2.32141 118.822 2.8985Z"
        fill="#7FBC42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.659 10.8237C119.659 10.8237 106.934 12.219 100.07 23.9393C99.4425 23.9858 98.813 23.8905 98.2278 23.6603C95.1582 22.6557 97.1674 19.9209 97.1674 19.9209C97.1674 19.9209 105.372 9.26099 119.659 10.8237Z"
        fill="#F05B26"
      />
      <path
        d="M130.375 24.8323C127.864 24.7207 125.52 26.9531 123.845 29.1856C122.423 33.1277 119.66 36.4441 116.039 38.5545C112.419 40.6649 108.171 41.4349 104.04 40.7298C99.9094 40.0247 96.1579 37.8893 93.4424 34.6974C90.7269 31.5054 89.2204 27.4602 89.1864 23.2696C89.1864 16.1258 93.3722 10.7121 99.4557 7.8657C105.986 3.56824 103.697 0.666053 103.697 0.666053C103.028 0.666053 102.358 0.107941 101.744 0.219563C88.9632 3.01013 80.8147 15.2886 83.6053 28.0694C84.2784 31.1026 85.5436 33.9732 87.3284 36.5164C89.1133 39.0597 91.3825 41.2256 94.0062 42.89C96.6298 44.5544 99.5562 45.6845 102.617 46.2156C105.679 46.7467 108.815 46.6682 111.846 45.9848C119.436 44.3663 125.408 38.7851 128.366 32.5343C128.478 32.5343 128.589 31.9762 128.701 31.6413C129.597 29.4879 130.162 27.2109 130.375 24.8881C130.375 24.8881 130.431 24.7765 130.375 24.8323Z"
        fill="#7FBC42"
      />
    </svg>
  );
};

Banner.Text = ({ children, ...rest }) => {
  return <Text {...rest}>{children}</Text>;
};

Banner.Loader = ({ ...rest }) => {
  return (
    <Loader>
      <span />
    </Loader>
  );
};
