import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { BannerContainer } from './containers/banner';
import { FooterContainer } from './containers/footer';
// import { NavContainer } from './containers/nav';

const Home = lazy(() =>
  import('./pages').then((module) => ({
    default: module.Home,
  }))
);
const Browse = lazy(() =>
  import('./pages').then((module) => ({
    default: module.Browse,
  }))
);

export const App = () => {
  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<BannerContainer />}>
          {/* <NavContainer /> */}
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/browse" exact component={Browse} />
          </Switch>
          <FooterContainer />
        </Suspense>
      </BrowserRouter>
    </>
  );
};
