import styled from 'styled-components/macro';

export const Group = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Container = styled.div`
  width: 200px;
  position: relative;
  margin-right: 15px;

  @media (max-width: 675px) {
    min-width: 155px;
    max-width: 180px;
    width: max-content;
  }
`;

export const Header = styled.div`
  margin-bottom: 0.5em;
  padding: 0.4em 2em 0.4em 1em;
  font-weight: 400;
  font-size: 18px;
  cursor: pointer;
  color: #464646;
  background: #eee;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;

  span {
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 30px;
    opacity: 0.6;
    transform: ${({ open }) => (open ? 'rotate(-90deg)' : 'rotate(90deg)')};
  }
`;

export const List = styled.ul`
  position: absolute;
  z-index: 10;
  left: 0;
  padding: 0;
  margin: 0;
  width: 250px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 400;

  @media (max-width: 500px) {
    width: 200px;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  padding: 0.6rem 0.4rem;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
  background: ${({ active }) => (active ? '#7FBD42' : 'transparent')};
  color: ${({ active }) => (active ? '#fff' : 'initial')};

  img {
    position: relative;
    top: 5px;
    margin-right: 5px;
  }

  &:hover {
    background: #eee;
    color: initial;
  }
`;
