import React from 'react';
import { Layout } from '../';
import {
  Container,
  Header,
  Logo,
  LogoLink,
  LinkTitle,
  Box,
  Items,
  ItemLabel,
  Menu,
  MobileMenuButton,
  MobileMenu,
  LinkItems,
  LinkItem,
  LinkItemsMobile,
  LinkItemMobile,
  MobileWrapper,
  ChevronRight,
  ChevronLeft,
} from './styles/navbar';

export const Navbar = ({ children, light, ...rest }) => {
  return (
    <Box light={light}>
      <Layout.Container>
        <Container {...rest}>{children}</Container>
      </Layout.Container>
    </Box>
  );
};

Navbar.Header = ({ children, ...rest }) => {
  return <Header {...rest}>{children}</Header>;
};

Navbar.Logo = ({ src, alt = 'Logo', ...rest }) => {
  return (
    <LogoLink href="https://www.sivioinstitute.org/" target="_blank">
      <Logo src={src} alt={alt} />
    </LogoLink>
  );
};

Navbar.LinkTitle = ({ children, to, ...rest }) => {
  return (
    <LinkTitle to={to} {...rest}>
      {children}
    </LinkTitle>
  );
};

Navbar.Items = ({ children, ...rest }) => {
  return <Items {...rest}>{children}</Items>;
};

Navbar.ItemLabel = ({ children, ...rest }) => {
  return <ItemLabel {...rest}>{children}</ItemLabel>;
};

Navbar.ItemLabel = ({ children, ...rest }) => {
  return <ItemLabel {...rest}>{children}</ItemLabel>;
};

Navbar.Menu = ({ src, ...rest }) => {
  return <Menu src={src} {...rest} />;
};

Navbar.MobileMenuButton = ({ src, ...rest }) => {
  return <MobileMenuButton src={src} {...rest} />;
};

Navbar.MobileMenu = ({ children, ...rest }) => {
  return <MobileMenu {...rest}>{children}</MobileMenu>;
};

Navbar.LinkItems = ({ children, ...rest }) => {
  return <LinkItems {...rest}>{children}</LinkItems>;
};

Navbar.LinkItem = ({ children, position, href, ...rest }) => {
  return (
    <LinkItem {...rest} position={position}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </LinkItem>
  );
};

Navbar.LinkItemsMobile = ({ children, ...rest }) => {
  return <LinkItemsMobile {...rest}>{children}</LinkItemsMobile>;
};

Navbar.MobileWrapper = ({ children, ...rest }) => {
  return <MobileWrapper {...rest}>{children}</MobileWrapper>;
};

Navbar.LinkItemMobile = ({ children, position, href, ...rest }) => {
  return (
    <LinkItemMobile {...rest} position={position}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </LinkItemMobile>
  );
};

Navbar.ChevronRight = ({ src, ...rest }) => {
  return <ChevronRight src={src} {...rest} />;
};

Navbar.ChevronLeft = ({ src, ...rest }) => {
  return <ChevronLeft src={src} {...rest} />;
};
