import styled from 'styled-components/macro';

export const Container = styled.section`
  background: #f8f9f9;
  padding: 3rem 0;
  margin-top: 6.2rem;
  position: relative;
  z-index: 100;
`;

export const Heading = styled.h2`
  font-weight: 500;
  font-size: 32px;
  line-height: 100%;
  color: #222222;
  margin-bottom: 2rem;
`;

export const Group = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Item = styled.a`
  padding: 0.6rem;
  display: block;
  text-decoration: none;
  color: #222;
  cursor: ${({ href }) => (href ? 'pointer' : 'not-allowed')};
  position: relative;

  &::after {
    position: absolute;
    display: block;
    content: '';
    top: 5%;
    right: 0;
    height: 90%;
    width: 1px;
    background: #c4c4c4;
  }

  &::before {
    position: absolute;
    display: block;
    content: '';
    top: 0;
    left: 5%;
    height: 1px;
    width: 90%;
    background: #c4c4c4;
  }

  &:nth-of-type(4),
  &:nth-of-type(8),
  &:nth-of-type(12) {
    &::after {
      display: none;
    }
  }

  &:nth-of-type(8) {
    &::after {
      position: absolute;
      display: block;
      content: '';
      height: 1px;
      width: 90%;
      top: unset;
      background: #c4c4c4;
      bottom: 0;
      left: 5%;
    }
  }

  @media (max-width: 600px) {
    &:nth-of-type(even) {
      &::after {
        display: none;
      }
    }
    &:nth-of-type(10) {
      &::after {
        position: absolute;
        display: block;
        content: '';
        height: 1px;
        width: 90%;
        top: unset;
        background: #c4c4c4;
        bottom: 0;
        left: 5%;
      }
    }
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  min-height: 250px;
  background: transparent;
  transition: box-shadow 500ms ease, background 500ms ease;

  &:hover {
    background: white;
    box-shadow: 0px 10px 45px rgba(55, 76, 91, 0.15);
    border-radius: 3px;
  }
`;
