import React from 'react';
import { Wrapper, Container } from './styles/nav';

export const Nav = ({ children, ...rest }) => {
  return (
    <Wrapper>
      <Container {...rest}>{children}</Container>
    </Wrapper>
  );
};

Nav.Logo = ({ children, ...rest }) => {
  return;
};

Nav.Search = ({ children, ...rest }) => {
  return;
};
