import styled from 'styled-components/macro';

export const Container = styled.div``;

export const Header = styled.div`
  border-radius: 4px;
  background: #eee;
  min-height: 235px;
  margin-bottom: 5px;
`;

export const Body = styled.div`
  border-radius: 4px;
  background: #eee;
  min-height: 20px;
`;

export const Link = styled.div`
  border-radius: 4px;
  background: #eee;
  height: 15px;
  width: 70%;
  max-width: 900px;
  margin-bottom: 18px;
`;
