import React, { useRef, useEffect } from 'react';
import WebViewer from '@pdftron/webviewer';
import { Wrapper, Container, Frame, BackButton } from './styles/viewer';

export const Viewer = ({ src, handleClick }) => {
  const viewerDiv = useRef(null);

  useEffect(() => {
    WebViewer(
      {
        path: 'lib',
        initialDoc: src,
      },
      viewerDiv.current
    ).then((instance) => {
      instance.disableFeatures([instance.Feature.TextSelection]);
      instance.setTheme('light');
    });
  });

  return (
    <Wrapper onClick={handleClick}>
      <Container>
        <Frame ref={viewerDiv} />
      </Container>
      <BackButton onClick={handleClick}>
        <img src="./images/svg/clear.svg" alt="Back Button" />
      </BackButton>
    </Wrapper>
  );
};
