import React from 'react';
import { Container, Item } from './styles/breadcrump';

export const BreadCrump = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

BreadCrump.Item = ({ children, to, ...rest }) => {
  return (
    <Item {...rest} to={to}>
      <span>{children} </span>
      <span>›</span>
    </Item>
  );
};
