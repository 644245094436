import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  border-bottom: 1px solid #ddd;
`;

export const Container = styled.div`
  max-width: 1250px;
  width: 85%;
  margin: auto;
`;
