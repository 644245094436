import React from 'react';
import { render } from 'react-dom';
import { GlobalStyles } from './styles/global';
import * as serviceWorker from './sw';
import { App } from './app';

render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.register();
