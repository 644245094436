import React from 'react';
import { Footer } from '../components';

export const FooterContainer = () => {
  return (
    <Footer>
      <Footer.Logo />
      <Footer.Text>A SIVIO Institute Initiative</Footer.Text>
    </Footer>
  );
};
