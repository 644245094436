import styled from 'styled-components';

export const Container = styled.div``;

export const Inner = styled.div`
  max-width: 1250px;
  width: 85%;
  padding: 2rem 0;
  margin: auto;
  display: flex;
  border-top: 1px solid #ddd;
`;

export const Text = styled.p`
  font-size: 20px;
  font-weight: 600;
  margin-left: 2rem;
  color: #555;
`;
