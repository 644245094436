import React from 'react';
import { Container, Wrapper, Video, Title, BackButton } from './styles/player';

export const Player = ({ url, children, handleClick, ...rest }) => {
  return (
    <Container {...rest} onClick={handleClick}>
      {children}
      <BackButton onClick={handleClick}>
        <img src="./images/svg/clear.svg" alt="Back button" />
      </BackButton>
    </Container>
  );
};

Player.Video = ({ url, title, ...rest }) => {
  return (
    <Wrapper>
      <Video url={url} width="100%" {...rest} />
      <Title>{title}</Title>
    </Wrapper>
  );
};
