import styled from 'styled-components/macro';

export const Box = styled.div``;

export const Container = styled.div`
  max-with: 1320px;
  width: 85%;
  margin: auto;

  @media (max-width: 800px) {
    width: 95%;
  }
`;
