import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

const overlayPosition = (id) => {
  if (id === '01') {
    return '0';
  } else if (id === '02') {
    return '31.7vw';
  }
  return '62.3vw';
};

export const Wrapper = styled.div`
  max-width: 1320px;
  width: 90%;
  margin: auto;
  display: flex;
  margin-top: 2rem;
  min-height: 550px;

  @media (max-width: 800px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 93%;
  }

  @media (max-width: 500px) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

export const Background = styled.div`
  @keyframes slideIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  display: none;
  pointer-events: none;
  animation: slideIn 1s ease-in-out forwards;
  position: fixed;
  top: 0;
  left: ${({ id }) => overlayPosition(id)};
  width: 36.433333vw;
  height: 100vh;
  z-index: 2;
  background: rgba(0, 0, 150, 0.5);
  backdrop-filter: blur(1rem);
  drop-shadow: 2px 16px 8px rbga(0, 0, 0, 0.125);
`;

export const Content = styled.div`
  // position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;

  @media (max-width: 400px) {
    position: static;
  }
`;

export const Body = styled.div`
  position: relative;
  padding: 0 2rem;
  height: 100%;

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const Number = styled.h2`
  font-weight: 500;
  font-size: 54px;
  line-height: 60px;
  color: #eeeeee;
  margin: 1.5rem 0;
  position: relative;
`;

export const Title = styled.h5`
  font-size: 32px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.015em;
  color: #292325;
  margin: 0 0 2rem 0;
  position: relative;
  max-width: 380px;
`;

export const Button = styled(RouterLink)`
  display: inline-block;
  height: 48px;
  line-height: 50px;
  cursor: pointer;
  font-size: 18px;
  color: #222;
  padding: 0 1.4rem;
  border: 1px solid #222;
  text-decoration: none;
  position: absolute;
  bottom: 3px;
  transition: transform 500ms ease-in-out;

  @media (max-width: 800px) {
    position: static;
  }

  &:hover {
    transform: scale(1.05);
    border: 2px solid #000;
  }

  &:hover img {
    transform: translateX(9px);
  }
`;

export const Item = styled.div`
  transition: all 600ms ease;
  flex-basis: 33.3333%;
  border-right: 1px solid #c4c4c4;
  position: relative;

  &:nth-of-type(3) {
    border: none;
  }

  &:hover {
    flex-basis: 35%;
  }

  &:hover div:nth-child(2) {
    display: block;
  }

  &:hover ${Number} {
    z-index: 7;
  }
  &:hover ${Title} {
    z-index: 7;
    color: #000;
  }
  &:hover ${Button} {
    z-index: 7;
    color: #000;
    border: 2px solid #000;
  }

  @media (max-width: 800px) {
    margin-bottom: 1.5rem;
    border: 0;
  }
`;

export const Icon = styled.img`
  transition: transform 300ms ease-in-out;
  padding-left: 1rem;
  position: relative;
  top: 3px;
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.6;
`;
