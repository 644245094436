import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @font-face {
      font-family: "TT Commons";
      src: url("./fonts/TT.Commons/TTCommons-Regular.ttf");
  }
  @font-face {
      font-family: "TT Commons Demibold";
      src: url("./fonts/TT.Commons/TTCommons-DemiBold.ttf");
  }
  @font-face {
      font-family: "TT Commons Medium";
      src: url("./fonts/TT.Commons/TTCommons-Medium.ttf");
  }

  @font-face {
      font-family: "TT Commons Bold";
      src: url("./fonts/TT.Commons/TTCommons-Bold.ttf");
  }

  html, body {
  font-family: 'TT Commons', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  font-size: 16px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;

}`;
