import React from 'react';
import { Banner } from '../components';

export const BannerContainer = () => {
  return (
    <Banner>
      <Banner.Logo />
      <Banner.Text>A SIVIO INSTITUTE Initiave</Banner.Text>
      <Banner.Loader />
    </Banner>
  );
};
