import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  place-items: center;
  height: 100vh;
`;

export const Inner = styled.div`
  display: grid;
  place-items: center;
`;

export const Text = styled.p`
  font-size: 1.5rem;
  font-weight: 500;
`;

export const Loader = styled.div`
  width: 150px;
  height: 10px;
  border-radius: 6px;
  background: #eee;
  position: relative;

  span {
    @keyframes slide {
      0% {
        left: 0;
      }
      100% {
        left: 100px;
      }
    }
    animation: slide 1s linear 0s infinite alternate;
    position: absolute;
    height: 10px;
    width: 50px;
    border-radius: 6px;
    background: #f05b26;
  }
`;
