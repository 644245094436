import React, { useEffect, useState } from 'react';
import { PopOver, Layout } from '../../components';
import {
  Heading,
  Header,
  SubHeader,
  Description,
  Divider,
  HeadingDivider,
  Inner,
  Body,
  Icon,
  Title,
  Text,
  Group,
  Frame,
  Actions,
  Button,
  Link,
  Image,
  VideoThumbnail,
  VideoThumbnailYT,
  VideoTitle,
} from './styles/card';

export const Card = ({
  children,
  path,
  size,
  type,
  name,
  title,
  created,
  modified,
  setFrame,
  span = 1,
  ...rest
}) => {
  const [index, setIndex] = useState(0);
  const colorMap = ['#7FBD42', '#ABCE00', '#CAEFB3', '#CAEFB3', '#ffffff'];

  useEffect(() => {
    const i = Math.floor(Math.random() * colorMap.length);
    setIndex(i);
  }, [colorMap.length]);
  return (
    <>
      {type === 'video' ? (
        <>
          <Frame span={span} type={type}>
            <Inner type={type} {...rest} color={colorMap[index]}>
              {children}
            </Inner>
            <PopOver style={{ width: 400 }}>
              <PopOver.Body>
                <PopOver.Header>
                  <img
                    src={`./images/svg/video.svg`}
                    alt=""
                    style={{ width: 20, marginRight: 15 }}
                  />
                  <span>{title}</span>
                </PopOver.Header>
                <PopOver.Text>
                  Created: {new Date(created).toDateString()},
                </PopOver.Text>
                <PopOver.Text>
                  Modified: {new Date(modified).toDateString()}
                </PopOver.Text>
              </PopOver.Body>
            </PopOver>
          </Frame>
        </>
      ) : (
        <Frame span={span} type={type}>
          <Inner type={type} {...rest} color={colorMap[index]}>
            {children}
          </Inner>
          <PopOver>
            <PopOver.Body>
              <PopOver.Header>
                <img
                  src={`./images/svg/pdf.svg`}
                  alt=""
                  style={{ width: 20, marginRight: 15 }}
                />
                <span>{title}</span>
              </PopOver.Header>
              <PopOver.Text>
                Created: {new Date(created).toDateString()},
              </PopOver.Text>
              <PopOver.Text>
                Modified: {new Date(modified).toDateString()}
              </PopOver.Text>
            </PopOver.Body>
          </PopOver>
          <Actions>
            <div>
              <Button onClick={() => setFrame(true)}>Read</Button>
              <Button href={path} download={name} target="_blank">
                Download
              </Button>
            </div>
            <Link>{(size / 1024).toFixed(2)}mb</Link>
          </Actions>
        </Frame>
      )}
    </>
  );
};

Card.Container = ({ children, ...rest }) => {
  return <Layout.Container {...rest}>{children}</Layout.Container>;
};

Card.Group = ({ children, ...rest }) => {
  return <Group {...rest}>{children}</Group>;
};

Card.Body = ({ children, ...rest }) => {
  return <Body {...rest}>{children}</Body>;
};

Card.Icon = ({ src, alt = '', ...rest }) => {
  return <Icon src={src} alt={alt} {...rest} />;
};

Card.Image = ({ src, alt = '', ...rest }) => {
  return <Image src={src} alt={alt} {...rest} />;
};

Card.Divider = ({ ...rest }) => {
  return <Divider {...rest} />;
};

Card.HeadingDivider = ({ ...rest }) => {
  return <HeadingDivider {...rest} />;
};

Card.VideoThumbnail = ({ src, ...rest }) => {
  return <VideoThumbnail {...rest} src={src} />;
};

Card.VideoThumbnailYT = ({ src, ...rest }) => {
  return <VideoThumbnailYT {...rest} src={src} />;
};

Card.Title = ({ children, ...rest }) => {
  return <Title {...rest}>{children}</Title>;
};

Card.Description = ({ children, ...rest }) => {
  return <Description {...rest}>{children}</Description>;
};

Card.VideoTitle = ({ children, ...rest }) => {
  return <VideoTitle {...rest}>{children}</VideoTitle>;
};

Card.Text = ({ children, ...rest }) => {
  return <Text {...rest}>{children}</Text>;
};

Card.Heading = ({ children, ...rest }) => {
  return <Heading {...rest}>{children}</Heading>;
};

Card.Header = ({ children, ...rest }) => {
  return <Header {...rest}>{children}</Header>;
};

Card.SubHeader = ({ children, ...rest }) => {
  return <SubHeader {...rest}>{children}</SubHeader>;
};
