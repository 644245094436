import React from 'react';
import { Container, Header, Body, Link } from './styles/skeleton';

export const Skeleton = ({ ...rest }) => {
  return <Container {...rest} />;
};

Skeleton.Header = ({ ...rest }) => {
  return <Header {...rest} />;
};

Skeleton.Body = ({ ...rest }) => {
  return <Body {...rest} />;
};

Skeleton.Link = ({ ...rest }) => {
  return <Link {...rest} />;
};
