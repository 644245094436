import React from 'react';
import {
  Wrapper,
  Item,
  Background,
  Content,
  Body,
  Number,
  Title,
  Button,
  Icon,
  Video,
} from './styles/hero';

export const Hero = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>;
};

Hero.Item = ({ children, ...rest }) => {
  return <Item {...rest}>{children}</Item>;
};

Hero.Background = ({ children, ...rest }) => {
  return <Background {...rest}>{children}</Background>;
};

Hero.Content = ({ children, ...rest }) => {
  return <Content {...rest}>{children}</Content>;
};

Hero.Body = ({ children, ...rest }) => {
  return <Body {...rest}>{children}</Body>;
};

Hero.Number = ({ children, ...rest }) => {
  return <Number {...rest}>{children}</Number>;
};

Hero.Title = ({ children, ...rest }) => {
  return <Title {...rest}>{children}</Title>;
};

Hero.Button = ({ children, to, ...rest }) => {
  return (
    <Button to={to} {...rest}>
      {children}
    </Button>
  );
};

Hero.Icon = ({ src, alt = '', ...rest }) => {
  return <Icon src={src} alt={alt} {...rest} />;
};

Hero.Video = ({ src, alt = '', ...rest }) => {
  return <Video src={src} alt={alt} {...rest} />;
};
