import styled from 'styled-components/macro';
import ReactPlayer from 'react-player';

export const Container = styled.div`
  @keyframes fadeIn {
    0% {
      top: -80px;
      opacity: 0;
    }
    90% {
      top: 10px;
      opacity: 0.2;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  animation: fadeIn 500ms ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
  z-index: 10;
`;

export const Wrapper = styled.div`
  width: 640px;
  height: auto;
  margin: auto;
  z-index: 12;

  @media (max-width: 600px) {
    width: 98%;
    height: auto;
  }
`;

export const Video = styled(ReactPlayer)`
  margin: auto;
  margin-top: 20vh;
`;

export const BackButton = styled.button`
  position: absolute;
  top: 2rem;
  left: 3rem;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0.6rem;
  border-radius: 50%;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    transform: scale(1.1);
  }

  img {
    width: 30px;
    filter: invert(1);
  }

  @media (max-width: 600px) {
    left: 1%;
  }
`;

export const Title = styled.h3`
  color: #eee;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
`;
