import React from 'react';
import { Container, Header, Body, Text } from './styles/popover';

export const PopOver = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

PopOver.Header = ({ children, ...rest }) => {
  return <Header {...rest}>{children}</Header>;
};

PopOver.Body = ({ children, ...rest }) => {
  return <Body {...rest}>{children}</Body>;
};

PopOver.Text = ({ children, ...rest }) => {
  return <Text {...rest}>{children}</Text>;
};
