import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  @keyframes fadeIn {
    0% {
      top: -80px;
      opacity: 0;
    }
    90% {
      top: 10px;
      opacity: 0.2;
    }
    100% {
      top: 0;
      opacity: 1;
    }
  }
  animation: fadeIn 500ms ease-in-out;
  background: #fff;
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.2rem);
`;

export const Container = styled.div`
  margin: auto;
  max-width: 1200px;
`;

export const Frame = styled.div`
  width: 100%;
  height: 100vh;

  @media (max-width: 600px) {
    height: 92vh;
    margin-top: 8vh;
  }
`;

export const BackButton = styled.button`
  position: absolute;
  top: 2.5rem;
  left: 3rem;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 0.6rem;
  border-radius: 50%;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
    transform: scale(1.1);
  }

  img {
    width: 30px;
    filter: invert(1);
  }
  @media (max-width: 600px) {
    left: 1%;
    top: 0.4rem;
  }
`;
