import React from 'react';
import { Container, Box } from './styles/layout';

export const Layout = () => {
  throw new Error('Use layout static properties');
};

Layout.Container = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

Layout.Box = ({ children, ...rest }) => {
  return <Box {...rest}>{children}</Box>;
};
