import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  transition: opacity 600ms ease-in-out, transform 300ms linear;
  z-index: 5;
  left: 70px;
  top: -50px;
  width: 300px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(1rem);
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translate(-30px, 15px);

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Header = styled.h4`
  font-size: 17px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.6rem;
  padding-bottom: 0.3rem;
  color: #222;
  display: flex;
  place-items: center;

  img {
    position: relative;
    top: -4px;
  }
`;

export const Body = styled.div`
  padding: 0 0.8rem 0.9rem 0.8rem;
`;

export const Text = styled.p`
  padding: 0 0.3rem;
  color: #555;
  margin: 0.2rem;
`;
