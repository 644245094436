import React, { createContext, useContext, useState } from 'react';
import { Group, Container, Header, List, ListItem } from './styles/dropdown';

const DropDownContext = createContext();

export const DropDown = ({ children, ...rest }) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  return (
    <DropDownContext.Provider
      value={{ open, setOpen, selectedOption, setSelectedOption }}
    >
      <Container {...rest}>{children}</Container>
    </DropDownContext.Provider>
  );
};

DropDown.Group = ({ children, ...rest }) => {
  return <Group {...rest}>{children}</Group>;
};

DropDown.Header = function DropDownHeader({ children, ...rest }) {
  const { open, setOpen, selectedOption } = useContext(DropDownContext);
  return (
    <Header
      {...rest}
      onClick={() => {
        setOpen(!open);
      }}
      onBlur={() => {
        setTimeout(() => setOpen(false), 300);
      }}
      tabIndex="0"
      open={open}
    >
      {selectedOption ? selectedOption : children}
      <span>›</span>
    </Header>
  );
};

DropDown.Menu = function DropDownMenu({ children, ...rest }) {
  const { open } = useContext(DropDownContext);
  return <>{open ? <List {...rest}>{children}</List> : null}</>;
};

DropDown.Item = function DropDownItem({
  children,
  value,
  slug,
  onChange,
  active,
  ...rest
}) {
  const { open, setOpen, setSelectedOption } = useContext(DropDownContext);
  return (
    <ListItem
      {...rest}
      tabIndex="0"
      active={active}
      onClick={() => {
        setSelectedOption(value);
        onChange(slug);
        setOpen(!open);
      }}
    >
      {children}
    </ListItem>
  );
};
