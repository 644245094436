import styled from 'styled-components/macro';

export const Container = styled.section`
  background: #fff;
  padding: 3rem 0;
`;

export const Group = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
  col-gap: 2rem;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export const Header = styled.h2`
  font-weight: 500;
  font-size: 32px;
  color: #222;
  margin: 0.3rem;

  @media (max-width: 500px) {
    font-size: 28px;
  }
`;

export const MoreButton = styled.button`
  display: block;
  margin: auto;
  cursor: pointer;
  font-size: 16px;
  color: #222;
  background: #fff;
  padding: 1rem 2.4rem;
  border: 1px solid #222;
  border-radius: 3px;
  margin-top: 2rem;
  transition: all 300ms linear;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  span {
    position: relative;
    top: -1px;
    font-size: 20px;
  }

  &:hover {
    padding-left: 2.8rem;
    padding-right: 2.8rem;
    background: #eee;
  }
`;

export const Divider = styled.hr`
  width: 190px;
  margin: 0;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #000;
  opacity: 0.1;
`;

export const Break = styled.div`
  margin-top: 25px;
`;

export const Link = styled.a`
  color: #222;
  font-size: 20px;
  line-height: 35px;
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  img {
    transition: transform 300ms ease-in-out;
  }

  &:hover img {
    transform: translateX(10px);
  }
  @media (max-width: 500px) {
    font-size: 17px;
  }
`;

export const Icon = styled.img`
  display: inline-inline;
  margin-left: 1rem;
`;
