import React from 'react';
import { Layout } from '../';
import {
  Container,
  Group,
  Header,
  Divider,
  Break,
  Link,
  Icon,
  MoreButton,
} from './styles/reference';

export const Reference = ({ children, ...rest }) => {
  return (
    <Container {...rest}>
      <Layout.Container {...rest}>{children}</Layout.Container>
    </Container>
  );
};

Reference.Container = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

Reference.Group = ({ children, ...rest }) => {
  return <Group {...rest}>{children}</Group>;
};

Reference.Header = ({ children, ...rest }) => {
  return <Header {...rest}>{children}</Header>;
};

Reference.More = ({ children, ...rest }) => {
  return <MoreButton {...rest}>{children}</MoreButton>;
};

Reference.Divider = ({ ...rest }) => {
  return <Divider />;
};

Reference.Break = ({ ...rest }) => {
  return <Break />;
};

Reference.Link = ({
  children,
  href,
  target = '_self',
  icon = './images/svg/link.svg',
  alt = '',
  ...rest
}) => {
  return (
    <Link href={href} target={target} {...rest}>
      {children}
      <Icon src={icon} alt="Link icon" />
    </Link>
  );
};

Reference.Icon = ({ children, ...rest }) => {
  return <Icon {...rest}>{children}</Icon>;
};
