import styled from 'styled-components/macro';

export const Group = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 25px;
  grid-auto-flow: dense;

  @media (max-width: 835px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    gap: 12px;
  }
`;

export const Heading = styled.div`
  margin: 2rem 0 0.8rem 0;
`;

export const Header = styled.h1`
  font-weight: 500;
  font-size: 40px;
  line-height: 100%;
  color: #292325;
  margin-bottom: 10px;

  @media (max-width: 500px) {
    font-size: 7vw;
  }
`;

export const Description = styled.p`
  max-width: 700px;
`;

export const SubHeader = styled.h1`
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  color: #555;
  margin-bottom: 10px;
  display: flex;
  place-items: center;

  img {
    position: relative;
    top: -5px;
    margin-right: 3px;
    width: 40px;
  }

  @media (max-width: 500px) {
    font-size: 5vw;
    img {
      width: 30px;
    }
  }
`;

export const HeadingDivider = styled.hr`
  width: 590px;
  margin: 0;
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #000;
  opacity: 0.1;

  @media (max-width: 595px) {
    width: 96%;
  }
`;

export const Frame = styled.div`
  grid-column: span ${({ type }) => (type === 'video' ? '2' : '1')};
  cursor: pointer;
  position: relative;

  &:hover > div {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const Inner = styled.div`
  border-radius: 9px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: ${({ color }) => color};
  min-height: 260px;
  overflow: hidden;
  position: relative;
`;

export const Body = styled.div`
  padding: 2.4rem 0.6rem 0.1rem 0.6rem;
`;

export const Icon = styled.img`
  width: auto;
  height: 90px;
  opacity: 0.95;
  display: block;
  margin: auto;
  position: relative;
  z-index: 3;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const Divider = styled.hr`
  border-style: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  border-color: #222;
  margin-top: 30px;
  width: 88%;
`;

export const Title = styled.p`
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  letter-spacing: 6%;
  color: #374c5b;
  font-weight: 400;
`;

export const Text = styled.p`
  text-transform: uppercase;
  text-align: center;
  font-size: clamp(14px, 0.95rem, 1rem);
  letter-spacing: 6%;
  color: #374c5b;
  font-weight: 400;
`;

export const Actions = styled.div`
  margin-top: 0.7rem;
  display: flex;
  justify-content: space-between;
`;
export const Button = styled.a`
  display: inline-block;
  text-decoration: none;
  color: #374c5b;
  border: 1px solid #374c5b;
  line-height: 24px;
  height: 24px;
  font-size: 16px;
  cursor: pointer;
  font-family: 'TT Commons';
  padding: 0rem 0.4rem;
  margin-right: 7px;
`;

export const Link = styled.span`
  background: transparent;
  border: none;
  line-height: 24px;
  height: 24px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'TT Commons';
  color: #374c5b;
  transform: translateY(2px);

  @media (max-width: 600px) {
    display: none;
  }
`;

export const VideoThumbnail = styled.video`
  position: absolute;
  top: 0;
  left: -3px;
  width: 105%;
  border-radius: 9px;
`;

export const VideoThumbnailYT = styled.iframe`
  position: absolute;
  top: 0;
  left: -3px;
  width: 105%;
  border-radius: 9px;
  min-height: 100%;
  border: 0;
  pointer-events: none;
`;

export const VideoTitle = styled.div`
  background: #abce00;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 9px;
`;
