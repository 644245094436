import React from 'react';
import { Layout } from '../';
import { Container, Heading, Group, Item, Wrapper } from './styles/partners';

export const Partners = ({ children, ...rest }) => {
  return (
    <Container>
      <Layout.Container {...rest}>{children}</Layout.Container>
    </Container>
  );
};

Partners.Heading = ({ children, ...rest }) => {
  return <Heading {...rest}>{children}</Heading>;
};

Partners.Group = ({ children, ...rest }) => {
  return <Group {...rest}>{children}</Group>;
};

Partners.Item = ({ children, website = '', ...rest }) => {
  return website !== '' ? (
    <Item href={website} target="_blank" {...rest}>
      <Wrapper>{children}</Wrapper>
    </Item>
  ) : (
    <Item
      title="This organization does not have a website, facebook page or a twitter handle"
      {...rest}
    >
      <Wrapper>{children}</Wrapper>
    </Item>
  );
};
