import styled from 'styled-components/macro';
import { Link as RouterLink } from 'react-router-dom';

export const Box = styled.div`
  background: ${({ light }) => (light ? '#f8f9f9' : '#fff')};
`;

export const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  align-items: center;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Logo = styled.img`
  width: 140px;
  height: auto;
  padding-right: 1rem;
  border-right: 1px solid #c4c4c4;

  @media (max-width: 550px) {
    width: 25vw;
    padding-right: 0.4rem;
  }
`;

export const LogoLink = styled.a`
  text-decoration: none;
`;

export const LinkTitle = styled(RouterLink)`
  text-transform: uppercase;
  color: #7fbd42;
  font-size: 16px;
  font-weight: 600;
  padding-left: 1rem;
  text-decoration: none;

  @media (max-width: 550px) {
    font-size: 3vw;
    padding-left: 0.4rem;
  }
`;

export const Items = styled.div`
  width: 600px;
  display: flex;
  height: 35px;
  border-top: 1px solid rgba(196, 196, 196, 0.5);
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
  position: relative;
  top: -5px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Menu = styled.img`
  padding-right: 10px;
  width: 25px;
`;

export const ItemLabel = styled.p`
  color: #7fbd42;
  text-transform: uppercase;
  margin-top: 10px;
  vartical-align: middle;
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
`;

export const LinkItems = styled.ul`
  list-style: none;
  overflow-x: hidden;
  height: 20px;
  display: flex;
  transform: translateY(-6px);
  width: 300px;
`;

export const LinkItem = styled.li`
  margin-right: 20px;
  text-transform: uppercase;
  min-width: max-content;
  transform: translateX(${({ position }) => position}px);
  transition: all 0.4s linear;

  a {
    font-size: 14px;
    color: #374c5b;
    text-decoration: none;
    opacity: 0.5;
    font-weight: 400;
  }
`;

export const ChevronRight = styled.img`
  width: 10px;
  background: inherit;
  padding: 0 0.8rem;
  opacity: 0.9;
  box-shadow: -1px 0px 9px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transform: translateX(8px);
`;

export const ChevronLeft = styled.img`
  width: 10px;
  background: inherit;
  padding: 0 0.8rem;
  opacity: 0.9;
  backdrop-filter: blur(0.4rem);
  cursor: pointer;
  z-index: 2;
  box-shadow: -1px 0px 9px 0px rgba(0, 0, 0, 0.1);
  transform: rotate(180deg) translateX(-30px);
`;

// Mobile Menu

export const MobileMenuButton = styled.img`
  position: relative;
  top: -5px;
  cursor: pointer;

  @media (max-width: 800px) {
    width: 35px;
  }

  @media (max-width: 550px) {
    width: 30px;
  }
  @media (min-width: 800px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(0.5rem);
  z-index: 10;
  transition: all 500ms linear;

  @media (min-width: 800px) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  width: 96%;
  margin: auto;
`;

export const LinkItemsMobile = styled.ul`
  list-style: none;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding-left: 5px;

  @media (max-width: 435px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const LinkItemMobile = styled.li`
  background: #eee;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100px;
  padding: 0.5rem;
  display: flex;
  text-transform: uppercase;

  a {
    display: block;
    height: 100%;
    width: 100%;
    padding-top: 45px;
    font-size: 18px;
    color: #374c5b;
    text-decoration: none;
    opacity: 0.7;
  }
`;
